.carouselContainer {
    max-width: 100vw;
    overflow: hidden;

    .swiper-container {
        overflow: visible;
    }

    .carouselSwiper {
        height: 100%;
    }

    .carouselSlide {
        width: auto;
        height: auto;
        margin: 10px 0;
    }

    .swiper-button-disabled {
        opacity: 0.5;
    }

    .swiper-button-lock {
        display: none;
    }
}
